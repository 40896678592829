import { mapActions, mapGetters } from 'vuex';

import rubrics from './config';

export default function (CATALOG_TOGGLE_MAIN, CATALOG_TOGGLE_SIDE, idActive) {
  const { [idActive]: item } = rubrics;

  return {
    i18n: require('./i18n').default,
    init({ store, target }) {
      if (target === 'node') {
        const { id, sub = {} } = item;
        return Promise.all(Object.values(sub).map(({ id: subId, main, side }) => {
          const fetchCatalog = (params, _target) => store.dispatch('catalogs/fetch', { _target, ...params })
            .catch(() => console.error('Ошибка при запросе данных для каталогов. Секция', 'id:', id, '. sub: id:', subId));

          const promises = [];
          if (main) {
            promises.push(fetchCatalog(main.params, CATALOG_TOGGLE_MAIN));
          }
          if (side) {
            promises.push(fetchCatalog(side.params, CATALOG_TOGGLE_SIDE));
          }
          return Promise.all(promises);
        }));
      }
    },
    data() {
      return {
        unhiddenChild: undefined,
        gaPrefix: (() => {
          switch (idActive) {
            case 1:
              return 'BU';
            case 2:
              return 'NA';
            case 3:
              return 'AllForAuto';
            default:
              return '';
          }
        })(),
      };
    },
    props: ['idSubActive'],
    computed: {
      ...mapGetters({
        catalogs: 'catalogs/toggle',
        response: 'catalogs/response',
        toggleParams: 'catalogs/toggleParams',
      }),
      mainList() {
        return this.getList('main', CATALOG_TOGGLE_MAIN);
      },
      sideList() {
        return this.getList('side', CATALOG_TOGGLE_SIDE);
      },
    },
    watch: {
      langId() {
        this.init();
      },
      idSubActive() {
        this.init();
      },
    },
    methods: {
      ...mapActions({
        fetch: 'catalogs/fetch',
      }),
      getData(data = {}, _target = '') {
        return this.fetch({ _target, ...data.params });
      },
      init() {
        this.unhiddenChild = undefined;
        const {
          sub: { [this.idSubActive]: { main, side } = {} } = {},
        } = item;
        const promises = [];
        if (main) {
          promises.push(this.getData(main, CATALOG_TOGGLE_MAIN));
        }
        if (side) {
          promises.push(this.getData(side, CATALOG_TOGGLE_SIDE));
        }
        return Promise.all(promises.map((request) => request.catch((e) => {
          console.error('[ERROR] ProductFactory init:', e.message);
        })));
      },
      gotoCatalog(payload) {
        // eslint-disable-next-line no-restricted-globals,no-undef
        location.href = payload;
      },
      unhideChild(index) {
        this.unhiddenChild = this.unhiddenChild === index ? undefined : index;
      },
      generateHref(hrefItem) {
        return `${this.gaPrefix === 'AllForAuto' || hrefItem.langPrefixDisabled ? '' : this.langPrefix}${hrefItem.link}`;
      },
      getDeactivatedLinks(type, toggleType) {
        const currentParams = this.toggleParams(toggleType);

        return Object.values(item.sub).flatMap((subItem) => {
          const section = subItem[type];
          if (!section) return [];

          const id = JSON.stringify({
            langId: this.langId,
            ...section.params,
          });

          if (id === currentParams) return [];

          const response = this.response(id);
          return response || [];
        });
      },
      getList(type, toggleType) {
        const currentLinks = this.catalogs(toggleType) || [];
        const deactivatedLinks = this.getDeactivatedLinks(type, toggleType) || [];

        return [
          ...currentLinks.map((data) => ({ ...data, hide: false })),
          ...deactivatedLinks.map((data) => ({ ...data, hide: true })),
        ];
      },
    },
    serverPrefetch() {
      return this.init();
    },
  };
}
